@use "../global" as global;

.l-top-fv {

  &__inner {
    @include global.inner(global.$width-base);

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: max(100vh, 800px);
    padding-top: global.$height-header-pc;

    @include global.media(tb-l) {
      padding-top: global.$height-header-sp;
    }
  }
}

.l-top-services {

  &__inner {
    padding: 120px 0;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-top-features {

  &__inner {
    padding: 120px 0;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-top-white-papers {

  &__inner {
    padding: 120px 0;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-top-event {

  &__inner {
    padding: 120px 0;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-top-climate-change {

  &__inner {
    padding: 120px 0;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-top-event {

  &__inner {
    padding: 120px 0;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-top-white-papers {

  &__inner {
    padding: 120px 0;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-top-news {

  &__inner {
    padding: 120px 0;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }

  &__wrapper {
    padding: 0 30px;

    @include global.media(tb) {
      padding: 0;
    }
  }
}

.l-services {
  background-color: global.$color-white;

  &__inner {
    padding: global.$padding-y-section-pc global.$padding-x-section;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}
