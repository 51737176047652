@use "../global" as global;

html {
  font-size: global.$baseFontSize + px;
}

body {
  font-family: global.$font-base;
  color: global.$color-font-black;
  background-color: global.$color-bg-light-gray;
  text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-size-adjust: 100%;
}

img {
  max-width: 100%;
}
